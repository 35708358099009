/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
 module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: true,
    showcase: {
      submit_success_message: 'A member of the Amp team will review and publish <br>your story within 1-3 business days.'
    }
  },
  icons: {
    type: 'default'
  },
  colors: {
    red: {
      name: 'Red',
      value: '#cc0000'
    },
    gray_1: {
      name: 'Gray 1',
      value: '#424242'
    },
    gray_2: {
      name: 'Gray 2',
      value: '#e2e2e2'
    },
    gray_3: {
      name: 'Gray 3',
      value: '#979797'
    },
    gray_4: {
      name: 'Gray 4',
      value: '#474747'
    },
    gray_5: {
      name: 'Gray 5',
      value: '#767676'
    },
    gray_6: {
      name: 'Gray 6',
      value: '#636363'
    },
    gray_7: {
      name: 'Gray 7',
      value: '#a49e99'
    },
    black_1: {
      name: 'Black 1',
      value: '#1e1e1e'
    },
    black_2: {
      name: 'Black 2',
      value: '#111111'
    },
    blue_1: {
      name: 'Blue 1',
      value: '#043458'
    },
    gray_8: {
      name: 'Gray 8',
      value: '#d8d8d8'
    },
    gray_9: {
      name: 'Gray 9',
      value: '#3f3f3f'
    }
  },
  fonts: {
    font_1: {
      name: 'Graphik',
      value: 'Graphik, serif'
    },
    font_2: {
      name: 'Graphik-Medium',
      value: 'Graphik-Medium, serif'
    },
    font_3: {
      name: 'Graphik-Semibold',
      value: 'Graphik-Semibold, serif'
    },
    font_4: {
      name: 'TiemposText',
      value: 'TiemposText, serif'
    }
  }
}
